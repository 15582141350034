import { render, staticRenderFns } from "./promo-index.vue?vue&type=template&id=38a1f3e8&scoped=true&"
import script from "./promo-index.vue?vue&type=script&lang=js&"
export * from "./promo-index.vue?vue&type=script&lang=js&"
import style0 from "./promo-index.vue?vue&type=style&index=0&id=38a1f3e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a1f3e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KContainer from '@web/components/core/k-container'
import KLayout from '@web/components/core/k-layout'
installComponents(component, {KContainer,KLayout})
