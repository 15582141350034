<template>
    <k-layout>
        <k-container class="tw-py-20 tw-bg-white tw-h-full">
            <div class="tw-max-w-4xl tw-mx-auto tw-mt-16">
                <h1 class="tw-text-new-gray-700 tw-text-center tw-heading-1">{{ $t('global.new') }} <span class="gradient">remote</span> {{ $t('promo.title') }}</h1>
                <p class="tw-leading-8 tw-text-new-gray-700 tw-text-xl tw-mt-6 tw-text-center" v-html="$t('promo.description')" />

                <div class="tw-mt-16">
                    <div v-if="!(projects && projects.length)" class="tw-space-y-6">
                        <project-preview-skeleton />
                        <project-preview-skeleton />
                        <project-preview-skeleton />
                        <project-preview-skeleton />
                        <project-preview-skeleton />
                        <project-preview-skeleton />
                    </div>
                    <div v-else class="tw-space-y-6">
                        <project-preview :project="project" v-for="project in projects" :key="project.id" />
                    </div>
                </div>
            </div>
        </k-container>
    </k-layout>
</template>

<script>
import ProjectPreview from "./../../projects/components/project-preview-new";
import ProjectPreviewSkeleton from "./../../projects/components/project-preview-skeleton-new";

export default {
    components: {
        ProjectPreview,
        ProjectPreviewSkeleton
    },
    computed: {
        projects() {
            const projects = this.$store.getters["NEW_PROJECTS/allProjects"];

            return [
                projects.find(project => project.id === "BZA040sD8fOFEk2W7Qhn"),
                projects.find(project => project.id === "eI8LP9zoYi3vUSKgAm00"),
                projects.find(project => project.id === "HudQy2LiE8eb03SvrrNv"),
                projects.find(project => project.id === "aLP2RUjMvVPBJbv6dz5V"),
                projects.find(project => project.id === "LyVmQI4EH8fGt0O9vg6j"),
                projects.find(project => project.id === "LfejXm40e0HWnSxT1kKS"),
                projects.find(project => project.id === "CVZG6kBZq7RRuOX9aU2h"),
                projects.find(project => project.id === "ODmVoR0X4YmE9yGcSSri"),
                projects.find(project => project.id === "KAjbhijrTqi9J5ymsYb0")
            ];
        }
    }
};
</script>

<style scoped>
h1 {
    font-size: 3.5rem;
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 2.5rem;
    }
}

.gradient {
    background-color: #8a2387;
    background-image: linear-gradient(65deg, #8a2387, #f27121);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}
</style>
